<img *ngIf="mobile" class="notification" [routerLink]="['/infos-advs']" src="../../../assets/icons/notification.svg">

<p *ngIf="mobile" class="compteur">
  <span *ngIf="infosUnread" matBadge="{{ infosUnread.length }}" matBadgeSize="small"></span>
</p>

<img *ngIf="mobile" class="messagerie-icon" [routerLink]="['/messagerie']" src="../../../assets/bar-icons/messagerie.svg">

<p *ngIf="mobile" class="compteur-messagerie">
  <span *ngIf="countUnread" matBadge="{{ countUnread.count }}" matBadgeSize="small"></span>
</p>


<div *ngIf="currentUser.role === 'Admin';  then admin else commercial" ></div>
<ng-template #admin>
  <div class="custom-content">
    <app-loading *ngIf='!customers'></app-loading>
    <div class="basic-container mt-6">
      <div class="example-container mat-elevation-z0">
        <div class="example-header">
          <mat-form-field class="recherche">
            <input matInput [(ngModel)]="userQuestion"
            type="text" name="userQuestion" id="userQuestions"
            (ngModelChange)="this.userQuestionUpdate.next($event)" placeholder="Recherche">
            <button mat-button matSuffix mat-icon-button>
              <mat-icon class="search">search</mat-icon>
            </button>
          </mat-form-field>

          <div>
            <button class="btn-secondarycrm ml-3 mb-4" (click)="addCustomer()">Nouveau client +</button>
          </div>
        </div>

        <mat-table #table [dataSource]="dataSource" [@listAnimation]="customers?.length">

          <ng-container matColumnDef="name">
            <mat-cell *matCellDef="let element"> {{(element.name.length > 10) ? ((element.name).slice(0, 10) + '...') : element.name}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="city">
            <mat-cell *matCellDef="let element">{{element.cp}} {{element.city}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="id">
            <mat-cell *matCellDef="let element" class="d-flex justify-content-end">
              <a [routerLink]="['/commercial-customer-case', element._id]"><img src="../../../assets/icons/arrow-right.svg"></a>
            </mat-cell>
          </ng-container>

          <mat-row *matRowDef="let row; columns: displayedColumnsMobile;"></mat-row>
        </mat-table>

      </div>

    </div>
  </div>
</ng-template>

<ng-template #commercial>
  <div class="custom-content">
    <app-loading *ngIf='!cases'></app-loading>
    <div class="basic-container mt-6">
      <div class="example-container mat-elevation-z0">
        <div class="example-header">
          <mat-form-field class="recherche">
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Recherche">
            <button mat-button matSuffix mat-icon-button>
              <mat-icon class="search">search</mat-icon>
            </button>
          </mat-form-field>

          <div>
            <button class="btn-secondarycrm ml-3 mb-4" (click)="addCustomer()">Nouveau client +</button>
          </div>
        </div>
        <div *ngIf="currentUser.role != 'Commercial'">
          <mat-select placeholder="Selectionner un commercial">
            <mat-option *ngFor="let commercial of commerciaux" [value]="commercial.username" (click)="changeUserId(commercial._id)">{{ commercial.username }}</mat-option>
          </mat-select>
        </div>


        <mat-table #table [dataSource]="dataSource" *ngIf="!mobile" [@listAnimation]="cases?.length">

          <ng-container matColumnDef="name">
            <mat-cell *matCellDef="let element"> {{element.customer?.name}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="city">
            <mat-cell [ngClass]="element.customer?.cp ? 'custom-cell' : ''" *matCellDef="let element">{{element.customer?.cp}} {{element.customer?.city}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="mobile">
            <mat-cell *matCellDef="let element"> {{element.customer?.mobile}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="mail">
            <mat-cell *matCellDef="let element">
              <a *ngIf="element.customer?.mail" href="mailto:{{element.customer?.mail}}"><mat-icon>email</mat-icon></a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="id">
            <mat-cell *matCellDef="let element">
              <button *ngIf="!mobile" class="btn-tertiarycrm" [routerLink]="['/commercial-customer-case', element.customer?._id]">Accès Affaire ></button>
            </mat-cell>
          </ng-container>

          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <mat-table #table [dataSource]="dataSource" *ngIf="mobile" [@listAnimation]="cases?.length">

          <ng-container matColumnDef="name">
            <mat-cell *matCellDef="let element"> {{(element.customer?.name.length > 10) ? ((element.customer?.name).slice(0, 10) + '...') : element.customer?.name}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="city">
            <mat-cell *matCellDef="let element">{{element.customer?.cp}} {{element.customer?.city}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="id">
            <mat-cell *matCellDef="let element" class="d-flex justify-content-end">
              <a [routerLink]="['/commercial-customer-case', element.customer?._id]"><img src="../../../assets/icons/arrow-right.svg"></a>
            </mat-cell>
          </ng-container>

          <mat-row *matRowDef="let row; columns: displayedColumnsMobile;"></mat-row>
        </mat-table>

      </div>

    </div>
  </div>
</ng-template>


