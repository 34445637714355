import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService } from '@app/_services';
import { CasesService } from '@app/services/cases.service';
import { CustomersService } from '@app/services/customers.service';
import { InfosService } from '@app/services/infos.service';
import { UsersService } from '@app/services/users.service';
import { TmkCasesComponent } from '@app/tmk/tmk-cases/tmk-cases.component';
import { apiUrl } from '@environments/env';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

const  listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(
      ':enter',
      [
        style({ opacity: 0 }),
        stagger('60ms', animate('600ms ease-out', style({ opacity: 1 }))),
      ],
      { optional: true }
    ),
  ]),
]);

@Component({
  selector: 'app-commercial-customer-list',
  templateUrl: './commercial-customer-list.component.html',
  styleUrls: ['./commercial-customer-list.component.scss'],
  animations: [listAnimation],
})
export class CommercialCustomerListComponent implements OnInit {
  countUnread;
  cases;
  currentUser;
  customers;
  mobile;
  searchText: string;

  displayedColumns = ['name', 'city', 'mobile', 'mail', 'id'];
  displayedColumnsMobile = ['name', 'city', 'id'];

  dataSource;


  showUnread = true;
  showRead = true;
  infos;
  infosRead = [];
  infosUnread = [];
  public userQuestion: string;
  userQuestionUpdate = new Subject<string>();
  filterCommercialCustomerSubject = new Subject<string>();
  userActive;
  commerciaux = [];

  pageSize: number = 30;

  constructor(
    private sCases: CasesService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private sInfos: InfosService,
    private sCustomer: CustomersService,
    private http: HttpClient,
    private sCustomers: CustomersService,
    private sUsers: UsersService
  ) {
    this.authenticationService.user.subscribe((x) => (this.currentUser = x));
    this.userActive = JSON.parse(atob(this.currentUser.token.split('.')[1]));

    if (this.currentUser.role != 'Admin') {
      this.sUsers
        .getCommercialByAgency(this.userActive.agency.replace('Ã©', 'é'))
        .subscribe((res) => {
          this.commerciaux = res['user'].sort((u) => u.role);
        });
    }
    this.showCases();
    this.userQuestionUpdate
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((value) => {
        this.research(value);
      });

    this.filterCommercialCustomerSubject
      .pipe(debounceTime(400))
      .subscribe((filterValue) => {
        this.performSearch(filterValue);
      });
  }

  ngOnInit(): void {
    if (window.screen.width <= 600) {
      this.mobile = true;
    }

    this.showInfosByUserId();
    this.getGroups();
  }

  research(name) {
    if (name != '') {
      this.sCustomers.searchCustomer(name).then((res) => {
        this.dataSource = new MatTableDataSource(res);
      });
    } else {
      this.showCases();
    }
  }

  changeUserId(id) {
    this.sCases.getAllCasesByCommercialId(id).subscribe((res) => {
      this.cases = res;
      this.dataSource = new MatTableDataSource(this.cases);
    });
  }
  showCases() {
    if (this.currentUser.role == 'Admin') {
      this.sCustomer.getAllCustomers(50).subscribe((res) => {
        this.customers = res['customers'];
        this.dataSource = new MatTableDataSource(this.customers);
      });
    } else {
      this.sCases
        .getCasesByCommercialId(this.currentUser.id, this.pageSize)
        .subscribe((res) => {
          this.cases = res.data;
          this.dataSource = new MatTableDataSource(this.cases);
        });
    }
  }

  addCustomer(): void {
    const dialogRef = this.dialog.open(TmkCasesComponent, {
      width: '40%',
      height: '80%',
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.showCases();
    });
  }

  showInfosByUserId() {
    this.sInfos.getInfoByAdvId(this.currentUser.id).subscribe((res) => {
      this.infos = res;
      this.infos.forEach((element) => {
        if (element.vu) {
          this.infosRead.push(element);
        } else {
          this.infosUnread.push(element);
        }
      });
    });
  }

  applyFilter(filterValue: string) {
    this.filterCommercialCustomerSubject.next(filterValue);
  }

  private performSearch(filterValue: string) {
    if (filterValue !== '') {
      this.sCustomers
        .searchCustomerByCommercial(filterValue, this.currentUser.id)
        .subscribe(
          (res) => {
            this.cases =
              res && res.length > 0
                ? res.map((customer) => ({
                    customer: {
                      _id: customer._id,
                      name: customer.name,
                      cp: customer.cp,
                      city: customer.city,
                      mobile: customer.mobile,
                      mail: customer.mail,
                    },
                  }))
                : [];

            this.dataSource = new MatTableDataSource(this.cases);
          },
          (error) => {
            console.error('Erreur lors de la recherche :', error);
          }
        );
    } else {
      this.showCases();
    }
  }

  async getGroups(): Promise<void> {
    try {
      const countUnread: any = await this.http
        .get(apiUrl.apiUrl + '/messages/count-unread')
        .toPromise();
      this.countUnread = countUnread;
    } catch (error) {}
  }
}
