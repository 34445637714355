import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProfileComponent } from '@app/profile/profile.component';
import { InfosService } from '@app/services/infos.service';
import { PicturesService } from '@app/services/pictures.service';
import { RolesService } from '@app/services/roles.service';
import { TasksService } from '@app/services/tasks.service';
import { User, Role } from '@app/_models';
import { AuthenticationService } from '@app/_services';
import { apiUrl } from '@environments/env';
import { ChatserviceService } from '@app/services/chatservice.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  activeGroup = undefined;
  channels = [];
  directs = [];
  isSidenavOpen = false;
  isDataAvailable = false;
  user: User;
  currentPicture;
  countMessage;
  showDot = false;
  ecart;
  stylax = false;
  showPicture = false;
  showPopupNotif = false;
  infos;
  infosRead = [];
  infosUnread = [];
  showUnread = true;
  showRead = false;
  assignedTasks;
  tasks;
  mobile;

  countUnread;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    public sRoles: RolesService,
    private sPictures: PicturesService,
    private dialog: MatDialog,
    private sInfos: InfosService,
    private http: HttpClient,
    private sTasks: TasksService,
    private chatService: ChatserviceService
  ) {
    this.authenticationService.user.subscribe((x) => (this.user = x));

    this.chatService.newMessageReceived().subscribe((data: any) => {
      this.getGroups().then(() => this.displayBadge());
    });
  }

  get isAdmin() {
    return this.user && this.user.role === Role.Admin;
  }
  get isCommercial() {
    return this.user && this.user.role === Role.Commercial;
  }
  get isRespCommercial() {
    return this.user && this.user.role === Role.RespCommercial;
  }
  get isUser() {
    return this.user && this.user.role === Role.User;
  }
  get isTmk() {
    return this.user && this.user.role === Role.Tmk;
  }
  get isSuperTmk() {
    return this.user && this.user.role === Role.SuperTmk;
  }
  get isAdv() {
    return this.user && this.user.role === Role.Adv;
  }
  get isRespTech() {
    return this.user && this.user.role === Role.RespTechnicien;
  }
  get isCompta() {
    return this.user && this.user.role === Role.Compta;
  }

  logout() {
    this.authenticationService.logout();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.getPictureByUserId();
    }, 2000);
    this.showInfosByAdvId();
    this.showTasksByTargetId();
    if (window.screen.width <= 900) {
      this.mobile = true;
    }
    this.getGroups().then(() => this.displayBadge());
  }

  displayBadge() {
    if (this.countUnread.count === 0) {
      this.isDataAvailable = false;
    } else {
      this.isDataAvailable = true;
    }
  }

  goHome() {
    if (
      this.user.role == 'Admin' &&
      this.user.mail != 'communication2@enerconfort.com'
    ) {
      this.router.navigate(['/admin-dashboard']);
    } else if (this.isRespTech) {
      this.router.navigate(['technicien-dashboard']).then(() => {
        window.location.reload();
      });
    } else if (this.user.role != "Responsable d'agence") {
      this.router.navigate(['/' + this.user.role.toLowerCase() + '-dashboard']);
    } else {
      this.router.navigate(['/commercial-dashboard']);
    }
  }
  getPictureByUserId() {
    this.sPictures.getPicturesByUserId(this.user.id).subscribe((res) => {
      const pp = res[0];
      if (pp != undefined) {
        this.currentPicture = pp.image;
      }
      if (this.currentPicture) {
        if (this.currentPicture.length > 0) {
          this.showPicture = true;
        }
      }
    });
  }

  openDialog(): void {
    this.showPopupNotif = false;
    const dialogRef = this.dialog.open(ProfileComponent, {
      width: '45%',
      height: '60%',
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  showInfosByAdvId() {
    this.infosRead = [];
    this.infosUnread = [];
    this.sInfos.getInfoByAdvId(this.user.id).subscribe((res) => {
      this.infos = res;
      this.infos.forEach((element) => {
        if (element.vu) {
          this.infosRead.push(element);
        } else {
          this.infosUnread.push(element);
        }
      });
    });
  }

  showUnreadNotif() {
    this.showUnread = true;
    this.showRead = false;
  }

  showAll() {
    this.showUnread = true;
    this.showRead = true;
  }

  showPopup() {
    this.showPopupNotif = !this.showPopupNotif;
  }

  saveChange(info, infoId) {
    info.vu = !info.vu;
    this.sInfos.editInfo(info, infoId).then((res) => {
      if (res['error']) {
        return res.error;
      }
      let indexNotif;
      indexNotif = this.infosUnread.findIndex((notif) => notif._id == infoId);
      this.infosUnread.splice(indexNotif, 1);
      this.infosRead.push(info);
      this.infosRead.sort((a, b) => (a.created_at > b.created_at ? -1 : 1));
    });
  }

  goToCommercialCustomerList() {
    this.router.navigate(['commercial-customer-list']);
  }

  goToTravauxList() {
    this.router.navigate(['technicien-list-chantier']);
  }

  goToCustomerList() {
    this.router.navigate(['admin-customer-list']);
  }


  goToCase(id, info, infoId) {
    if (this.user.role == 'Compta') {
      this.router.navigate(['compta-case-detail', id]);
    } else if (
      this.user.role == 'Commercial' ||
      this.user.role == "Responsable d'agence"
    ) {
      this.router.navigate(['commercial-template', id]);
    } else {
      this.saveChange(info, infoId);
      this.router.navigate(['case-template', id]);
    }
  }

  deleteInfo(id) {
    this.sInfos.delete(id).subscribe((response) => {
      this.infosUnread = [];
      this.infosRead = [];
      this.showInfosByAdvId();
    });
  }

  async getGroups(): Promise<void> {
    try {
      const countUnread: any = await this.http
        .get(apiUrl.apiUrl + '/messages/count-unread')
        .toPromise();
      this.countUnread = countUnread;

      const groups: any = await this.http
        .get(apiUrl.apiUrl + '/groups')
        .toPromise();
      this.channels = groups.filter((g) => g.type === 'channel');
      this.directs = groups.filter((g) => g.type === 'direct');
      if (!this.activeGroup) {
        if (this.channels.length) this.activeGroup = this.channels[0];
        else if (this.directs.length) this.activeGroup = this.directs[0];
      }
      this.isSidenavOpen = false;
    } catch (error) {}
  }

  showTasksByTargetId() {
    return new Promise((resolve) => {
      this.sTasks.getTaskByTargetId(this.user.id).subscribe(
        (res) => {
          this.tasks = res;
          resolve(res);
        },
        (error) => {
          console.log(error);
        }
      );
    });
  }
}
