import { UsersService } from './../../services/users.service';
import { TasksService } from './../../services/tasks.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthenticationService, } from '@app/_services';
import { CustomersService } from '@app/services/customers.service';
import { map, startWith } from 'rxjs/operators';
import { CasesService } from '@app/services/cases.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddBarComponent } from '@app/snackbars/add-bar/add-bar.component';
import { EditBarComponent } from '@app/snackbars/edit-bar/edit-bar.component';
import { HistoriqueService } from '@app/services/historique.service';

@Component({
  selector: 'app-create-task',
  templateUrl: './create-task.component.html',
  styleUrls: ['./create-task.component.scss']
})
export class CreateTaskComponent implements OnInit {

  currentUser;
  task = {
    message: '',
    commentary: '',
    state: '',
    date: null,
    _id: '',
    target: '',
    tag: 'Dossier',
    created_by: {
      _id: '',
    },
    created_at: null,
    caseId: {
      _id: '',
    },
    customerId: '',
  }
  case;
  date = new FormControl(new Date());

  users
  taskId
  taskEdit = false
  targets = []
  customers = [];
  commercialCustomers = [];

  customer = {
    name: '',
    id: '',
  }

  myControl = new FormControl();

  customerCases;
  filteredOptions;
  customerPlaceholder = 'Chargement...'
  constructor(
    private dialogRef: MatDialog,
    private authenticationService: AuthenticationService,
    private sTask: TasksService,
    private sUsers: UsersService,
    private sCustomers: CustomersService,
    private sCases: CasesService,
    private _snackBar: MatSnackBar,
    private sHistoriques: HistoriqueService,
    @Inject(MAT_DIALOG_DATA) public data,
    @Inject(MAT_DIALOG_DATA) public caseId,
    @Inject(MAT_DIALOG_DATA) public inCase,
    @Inject(MAT_DIALOG_DATA) public edit,
  ) {

    this.authenticationService.user.subscribe(x => this.currentUser = x);
    this.task.target = this.currentUser.id;
    this.task.caseId = data.caseId;
    this.task.customerId = data.customerId

    if (data.task != undefined) {
      this.taskId = data.task._id
      this.task.message = data.task.message
      this.task.state = data.task.state
      this.task.date = data.task.date
      this.task.commentary = data.task.commentary,
        this.task._id = this.taskId
      this.taskEdit = true
      this.task.caseId = data.task.caseId,
        this.task.customerId = data.task.customerId
      this.task.target = data.task.target._id
      this.task.tag = data.task.tag;

      this.task.created_by = data.task.created_by._id;

    }
  }

  ngOnInit(): void {
    if (this.currentUser.role === 'Commercial') {
      this.showCustomersByCommercialId()
    }
    else if (this.currentUser.role === 'Responsable d\'agence') {
      //this.showCustomersByResponsableId()
    }
    else {
      this.getAllCustomers()
    }
    if (this.edit.edit) {
      this.showCaseById(this.task.caseId._id)
    }
    else {
      this.showCaseById(this.caseId.caseId);
    }
  }

  showCaseById(caseId) {
    this.sCases.getCaseById(caseId).subscribe(res => {
      this.case = res[0];
      this.getAllTarget(this.case.commercial)

    })
  }

  addTask() {
    this.task.created_by = this.currentUser.id;
    this.task.created_at = new Date();
    this.task.date = this.date.value;
    this.sTask.CreateTask(this.task).then(res => {
      console.log(res);
      if (res['error']) {
        alert(res.error);
      }
      else {
        this.sHistoriques.createHistorique(this.task.caseId, this.task.customerId, "ajouté", "Une tâche", this.task.message, this.currentUser.id).then(res => {
          console.log(res);
          if (res['error']) {
            alert(res);
          }
        });
        this.closeModale();
        this.addSnackBar()
      }
    });
  }

  editTask() {
    this.sTask.editTask(this.task, this.taskId).then(res => {

      console.log(res);
      this.sHistoriques.createHistorique(this.case._id, this.case.customer._id, "edité", " la tâche", this.task.message, this.currentUser.id).then(res => {
        console.log(res);
        if (res['error']) {
          alert(res);
        }
      });

      this.editSnackBar()
      if (res['error']) {
        alert(res.error);
      }
      else {
        this.closeModale();
      }
    });
  }

  editSnackBar() {
    this._snackBar.openFromComponent(EditBarComponent, { duration: 2000, panelClass: ['edit-snackbar'] });
  }

  getAllTarget(commercial) {
    this.targets.push(commercial)
    this.sUsers.getAllUsers().subscribe(res => {
      this.users = res['users'];

      this.users.forEach(element => {
        if (element.role === 'Adv' || element.role === 'Admin' || element.role === 'Technicien' || element.role === 'Responsable Technicien' || element.role === 'Compta' || element.role === 'Logistique') {
          this.targets.push(element);
        }
      });
    });
  }

  getAllCustomers() {
    this.sCustomers.getAllCustomers(1000).subscribe(res => {
      this.customers = res['customers'];
      this.customerPlaceholder = 'Sélectionner le client'
      this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filter(name) : this.customers.slice())
        );
    });
  }
  showCustomersByCommercialId() {
    this.sCases.getAllCasesByCommercialId(this.currentUser.id).subscribe(res => {
      const cases: any = res;
      cases.forEach(element => {
        this.commercialCustomers.push(element.customer)
      });

      this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filter(name) : this.commercialCustomers.slice())
        );
    });
  }

  closeModale() {
    this.dialogRef.closeAll();
  }

  setState(value) {
    this.task.state = value
  }

  displayFn(user): string {
    return user && user.name ? user.name : '';
  }
  private _filter(name: string) {
    const filterValue = name.toLowerCase();

    return this.customers.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  showTargetByCaseId(customerCase) {
    this.sUsers.getUserById(customerCase.commercial._id).subscribe(res => {
      const commercialBySelectedCase = res[0];
      this.getAllTarget(commercialBySelectedCase)
    });
  }
  showCasesByCustomerId(customer) {
    this.task.customerId = customer._id;
    this.sCases.getAllCasesByCustomerId(customer._id).subscribe(res => {
      this.customerCases = res;
      console.log(res)
    });
  }

  addSnackBar() {
    this._snackBar.openFromComponent(AddBarComponent, {
      duration: 2000,
      panelClass: ['add-snackbar']
    });
  }
}
