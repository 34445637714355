<app-commentbar [caseId]="caseId"></app-commentbar>
<a class="nav-item nav-link" routerLinkActive="active">
  <img *ngIf="!displayPieces" class="disactive" (click)="showTechPieces()" src="../../assets/bar-icons/pieces.svg">
  <img *ngIf="displayPieces" (click)="showTechPieces()" class="active" src="../../assets/bar-icons/pieces-active.svg">
</a>
<div class="row" style="margin-right: 2rem;">
  <div class="col-6">
    <h4>Kit vendu</h4>
    <app-product-list [selectedProducts]="selectedProducts"></app-product-list>
  </div>
  <div class="col-4">
    <h4>Visite technique</h4>
    <div class="card">
      <p class="p-3" (click)="showPieces(case._id)">Voir la Visite</p>

    </div>
  </div>
</div>


<div *ngIf="!displayPieces" style="margin-right: 2rem; margin-left: -1rem;">
  <mat-tab-group  (focusChange)="changeCurrentKit($event)">
    <mat-tab style="height: 100%;" [label]="tab.name" *ngFor="let tab of tabs">
        <ng-template matTabContent>
          <div class="mt-4">

            <div *ngIf="tab.name === 'PAC Air/Air' || tab.name === 'PAC Air/Eau'">
              <form [formGroup]="pacaaFormGroup">
              <h5>Unité extérieure</h5>
              <div class="row">
                <div class="col-lg-3 col-s-6 col-xs-6 custom-col mt-3">
                  <label>N° série unité extérieure</label>
                  <input class="custom-input" type="text" [(ngModel)]="currentKit.n_serie_ue" formControlName="n_serie_ue" (change)="getCurrentKitAndSaveChange(this.pacaaFormGroup.value.n_serie_ue,'n_serie_ue')">
                  <div *ngIf="pacaaFormGroup.get('n_serie_ue').invalid && pacaaFormGroup.get('n_serie_ue').errors && (pacaaFormGroup.get('n_serie_ue').dirty || pacaaFormGroup.get('n_serie_ue').touched)">
                  <small class="text-danger"
                      *ngIf="pacaaFormGroup.get('n_serie_ue').hasError('required')">
                      Champ requis
                  </small>
                  </div>
                </div>
              </div>
              </form>

              <div *ngIf="currentKit.type_pac === 'Split'">
                <h5>Unité intérieure</h5>
                <div class="row">
                  <div class="col-lg-3 col-s-6 col-xs-6 custom-col mt-3" *ngFor="let split of currentKit.splits;let i = index;">
                    <label>N° série unité intérieure {{ i +1 }}</label>
                    <input class="custom-input" type="text" [value]="split.numero" [(ngModel)]="split.numero" (change)="editSplit(split.name,$event.target.value,'splits')">
                  </div>
                </div>

              </div>
            </div>

            <div *ngIf="tab.name === 'Adoucisseur d\'eau'">
              <form [formGroup]="adoucisseurFormGroup">
              <h5>Caisson isolé</h5>
              <div class="row">
                <div class="col-lg-3 col-s-6 col-xs-6 custom-col mt-3">
                  <label>N° série</label>
                  <input class="custom-input" type="text" [(ngModel)]="currentKit.n_serie" formControlName="n_serie" (change)="getCurrentKitAndSaveChange(this.adoucisseurFormGroup.value.n_serie,'n_serie')">
                  <div *ngIf="adoucisseurFormGroup.get('n_serie').invalid && adoucisseurFormGroup.get('n_serie').errors && (adoucisseurFormGroup.get('n_serie').dirty || adoucisseurFormGroup.get('n_serie').touched)">
                  <small class="text-danger"
                      *ngIf="adoucisseurFormGroup.get('n_serie').hasError('required')">
                      Champ requis
                  </small>
                  </div>
                </div>
              </div>
              </form>
            </div>
            <div *ngIf="tab.name === 'VMC Double Flux'">
              <form [formGroup]="vmcFormGroup">
              <h5>Caisson isolé</h5>
              <div class="row">
                <div class="col-lg-3 col-s-6 col-xs-6 custom-col mt-3">
                  <label>N° série caisson isolé</label>
                  <input class="custom-input" type="text" [(ngModel)]="currentKit.n_serie_caisson" formControlName="n_serie_caisson" (change)="getCurrentKitAndSaveChange(this.vmcFormGroup.value.n_serie_caisson,'n_serie_caisson')">
                  <div *ngIf="vmcFormGroup.get('n_serie_caisson').invalid && vmcFormGroup.get('n_serie_caisson').errors && (vmcFormGroup.get('n_serie_caisson').dirty || vmcFormGroup.get('n_serie_caisson').touched)">
                  <small class="text-danger"
                      *ngIf="vmcFormGroup.get('n_serie_caisson').hasError('required')">
                      Champ requis
                  </small>
                  </div>
                </div>
              </div>
              </form>
            </div>
            <div *ngIf="tab.name === 'Ballon thermodynamique'">
              <form [formGroup]="btFormGroup">
              <h5>Unité extérieure</h5>

              <div class="row">
                <div class="col-lg-3 col-s-6 col-xs-6 custom-col mt-3" *ngIf="this.currentKit.type_bt === 'split' || this.currentKit.unite === 'Unité extérieure'">
                  <label>N° série unité extérieure</label>
                  <input class="custom-input" type="text" [(ngModel)]="currentKit.n_serie_ue" formControlName="n_serie_ue" (change)="getCurrentKitAndSaveChange(this.btFormGroup.value.n_serie_ue,'n_serie_ue')">
                  <div *ngIf="btFormGroup.get('n_serie_ue').invalid && btFormGroup.get('n_serie_ue').errors && (btFormGroup.get('n_serie_ue').dirty || btFormGroup.get('n_serie_ue').touched)">
                  <small class="text-danger"
                      *ngIf="btFormGroup.get('n_serie_ue').hasError('required')">
                      Champ requis
                  </small>
                  </div>
                </div>

                <div class="col-lg-3 col-s-6 col-xs-6 custom-col mt-3">
                  <label>N° série unité intérieure</label>
                  <input class="custom-input" type="text" [(ngModel)]="currentKit.n_serie_ui" formControlName="n_serie_ui" (change)="getCurrentKitAndSaveChange(this.btFormGroup.value.n_serie_ui,'n_serie_ui')">
                  <div *ngIf="btFormGroup.get('n_serie_ui').invalid && btFormGroup.get('n_serie_ui').errors && (btFormGroup.get('n_serie_ui').dirty || btFormGroup.get('n_serie_ui').touched)">
                  <small class="text-danger"
                      *ngIf="btFormGroup.get('n_serie_ui').hasError('required')">
                      Champ requis
                  </small>
                  </div>
                </div>
              </div>

              </form>
            </div>

          <div *ngIf="tab.name === 'Panneaux solaires'">
            <h5>Modules</h5>
            <div class="row">
              <div class="col-lg-3 col-s-6 col-xs-6 custom-col mt-3" *ngFor="let module of currentKit.modules;let i = index;">
                <label>N° série Module {{ i +1 }}</label>
                <input class="custom-input" type="text" [value]="module.numero" [(ngModel)]="module.numero" (change)="editModule(module.name,$event.target.value,'modules')">
              </div>
            </div>


            <h5 class="mt-4">Onduleurs</h5>
            <div class="row">
              <div class="col-lg-3 col-s-6 col-xs-6 custom-col mt-3" *ngFor="let onduleur of currentKit.onduleurs;let i = index;">
                <label>N° série Onduleur {{ i +1 }}</label>
                <input class="custom-input" type="text" [value]="onduleur.numero" [(ngModel)]="onduleur.numero" (change)="editModule(onduleur.name,$event.target.value,'onduleurs')">
              </div>
            </div>
          </div>


          </div>
        </ng-template>
    </mat-tab>
  </mat-tab-group>

  <div class="jumbo">
    <button class="btn-secondarycrm" (click)="createSortieStock()" *ngIf="currentKitValid">+ Nouvelle sortie de stock</button>
  </div>

</div>

<div *ngIf="displayPieces" class="row">

  <mat-card class="col-xs-12 col-lg-3 col-sm-2"*ngFor="let piece of techPieces" (click)="GoToDetailPiece(piece._id)">
    <div>
    <img src="../../../assets/pieces/img-piece.png" class="card-img-top" alt="pieces">
    <p class="qualif"
    [ngClass]="{'attente':piece.qualification === 'En attente de qualification',
                'conforme':piece.qualification === 'Conforme',
                'nonconforme':piece.qualification === 'Non conforme',
                'nonconforme':piece.qualification === 'Manquante'

              }">{{ piece.qualification }}</p>
    <mat-card-content>
      <p class="title">{{ piece.title }}</p>
      <h1 style="position: absolute;
      font-size: 0.7em;
      right: 2%;
      bottom: -2%;"> {{piece.created_at |date: 'short'}}</h1>
      <p class="description" *ngIf="piece.description && !piece.commentary">{{ piece.description }}</p>
      <p class="description" style="opacity:0;" *ngIf="piece.description === null">v</p>
      <p class="description" *ngIf="piece.commentary != ''">{{piece.commentary}}</p>


    </mat-card-content>
  </div>
  </mat-card>

</div>
